import remark from "remark";
import remarkHTML from "remark-html";

const Markdown = (content) => {
  const markedDownContent = remark()
    .use(remarkHTML)
    .processSync(content)
    .toString();

  return markedDownContent;
}

export default Markdown