import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import '../components/scss/landing_pages/landing_page_group_subscriptions.scss'
import { Helmet } from 'react-helmet'
import useSiteMetadata from "../components/SiteMetadata"
import Markdown from "../components/Markdown"
import logo from "../img/logo.svg"
import formHeadingImg from "../img/janel.png"
import EmailForm from '../components/EmailForm'

export const LandingPageGroupSubscriptionsTemplate = ({
  title,
  subtitle,
  page_intro,
  text_block,
  helmet,
}) => {

  return (
    <div className="landing-page landing-page--group-subscriptions">
      <div className="content">
        <div className="content__inner">
          { helmet || '' }
          <header
            className="page-header"
          >
            <div className="page-header__top">
              <a className="back-link" href="https://www.nationalobserver.com">
                <i className="far fa-chevron-left"></i>
              </a>
            </div>
            <div className="page-header__inner">
              <div className="logo">
                <a href="https://www.nationalobserver.com">
                  <img src={logo} alt="Canada's National Observer" style={{width: '180px', height: '70px' }} />
                </a>
              </div>
              <h1 className="page-title mt-0">
                { title }
              </h1>
              <h2 className="subtitle mt-0">
                { subtitle }
              </h2>
              <div className="intro">
                <HTMLContent className={"page-intro"} content={Markdown(page_intro) } />
              </div>
            </div>
          </header>
          <section className="sidebar">
            <div className="sidebar__inner">
              <EmailForm
                formName={"group-subscriptions-contact-form"}
                formHeading={"Contact us to find out more about group subscriptions"}
                formHeadingImg={formHeadingImg}
                successMessage={"Thanks for reaching out, we'll be in touch soon."}
                buttonText={"Find out more"} />
            </div>
          </section>
          <main className="page-main">
            <div className={"groups-intro"}>
              { text_block }
            </div>

            <div className={"groups-grid"}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </main>

          <footer className="page__footer">
            {/*<Testimonials />*/}
          </footer>

        </div>
      </div>
    </div>
  )
}

LandingPageGroupSubscriptionsTemplate.propTypes = {
  contentComponent: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  page_intro: PropTypes.string,
  text_block: PropTypes.string,
  helmet: PropTypes.object,
}

const LandingPageGroupSubscriptions = ({ data, location }) => {
  const { markdownRemark: page } = data
  const { siteURL } = useSiteMetadata()

  return (
      <Layout location={location}>
        <LandingPageGroupSubscriptionsTemplate
            contentComponent={HTMLContent}
            title={page.frontmatter.title}
            subtitle={page.frontmatter.subtitle}
            page_intro={page.frontmatter.page_intro}
            text_block={page.frontmatter.text_block}
            content={page.html}
            helmet={
              <Helmet>
                <meta name="description" content="Canada's National Observer is excited to announce group subscription packages. Find out more." />
                <meta property="og:title" content={page.frontmatter.title} />
                <meta name="og:description" content="Canada's National Observer is excited to announce group subscription packages. Find out more." />
                <meta property="og:image" content={`${siteURL}/img/og-image.jpg`} />
                <meta name="twitter:title" content={page.frontmatter.title} />
                <meta name="twitter:description" content="Canada's National Observer is excited to announce group subscription packages. Find out more." />
                <meta name="twitter:image" content={`${siteURL}/img/og-image.jpg`} />
              </Helmet>
            }
        />
      </Layout>
  )
}

LandingPageGroupSubscriptions.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default LandingPageGroupSubscriptions

export const LandingPageGroupSubscriptionsQuery = graphql`
  query LandingPageGroupSubscriptions($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        page_intro
        text_block
      }
    }
  }
`
